@import "~react-tabs/style/react-tabs.scss";
.react-tabs {
  &__tab-list {
    margin: 0px 0 6px;
    margin-bottom: 0;
    border-bottom: 0;

    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }

  &__tab:last-of-type {
    margin-right: 0;
  }

  &__tab {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;

    height: var(--height-tab);

    transition: all 0.2s;
    border: 1px solid var(--tabs-color);
    margin-right: 1px;
    margin-top: -1px;

    display: flex;
    align-items: center;

    padding: 6px 2px 6px 12px;
    background: var(--tabs-color);
    border: 0;

    &:not(.react-tabs__tab--selected):hover {
      border-color: var(--tabs-color-hover);
      background-color: var(--tabs-color-hover);
    }

    &:not(.react-tabs__tab--selected):active {
      color: black;
      border-color: var(--tabs-color-active);
      background-color: var(--tabs-color-active);
    }

    &--selected {
      background: var(--tab-background-color-selected);
      color: var(--tab-text-color-selected);
      border-radius: 0;
      border-color: var(--tab-border-color-selected);
    }

    &:focus {
      box-shadow: none;
      border-color: none;
    }
  }

  &__tab-panel {
    &--selected {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }
}
